import {
  defaultActionShortkeys,
  createShortkeys,
  newPickupShortkeys,
  extraCreateShortkeys,
  exportShortkeys,
  checkPriceShortkeys,
  blockShortkeys,
  deleteShortkeys,
  downloadShortkeys,
  paymentShortkeys,
} from './shortKeys'

const editPrice = tableName => {
  return { tableName, text: 'Edytuj cenę', component: 'PriceForm', update: true, target: 'dialog' }
}
const deleteElement = (tableName, text = 'Usuń', endpoint = null, shortkey = deleteShortkeys, refreshTables = [], successMessage) => {
  return { tableName, endpoint, text, component: 'ConfirmDeleteDialog', target: 'dialog', shortkey, refreshTables, successMessage }
}
const createElement = (component, text = 'Dodaj', size = null, shortkey = createShortkeys) => {
  return { text, component, size, target: 'dialog', shortkey }
}

export default {
  addNewOrder: { ...createElement('NewOrder', 'Nowe zlecenie', 'big'), isExpendable: true },
  editOrder: { name: 'editOrder', text: 'Edytuj zlecenie', component: 'EditOrderIntermediary', target: 'dialog', size: 'big', },
  copyOrder: { name: 'copyOrder', text: 'Powiel zlecenie', component: 'CopyOrder', target: 'dialog', size: 'big' },
  NewOrderSchedule: { ...createElement('NewSwap', 'Nowy harmonogram', 'big', extraCreateShortkeys), schedule: true },
  addNewPickup: createElement('NewPickup', 'Zabranie kontenera', null, newPickupShortkeys),
  addNewSwap: { ...createElement('NewSwap', 'Wymiana kontenera', 'big', extraCreateShortkeys), isExpendable: true },
  addNewSchedule: createElement('addNewSchedule', 'Stwórz harmonogram', extraCreateShortkeys),
  deleteOrder: { name: 'deleteOrder', ...deleteElement('orders', 'Usuń zlecenie') },
  newOrderFromTemplate: { name: 'newOrderFromTemplate', ...createElement('NewOrderFromTemplate', 'Przekształć w zlecenie', 'big'), },

  pickupContainer: { name: 'pickupContainer', component: 'NewPickup', text: 'Zabierz kontener', props: { specificContainer: true }, target: 'dialog', },
  swapContainer: { name: 'swapContainer', component: 'NewSwap', text: 'Wymień kontener', props: { specificContainer: true }, target: 'dialog', size: 'big', },

  addNewClient: createElement('NewClient', 'Nowy klient'),
  editClient: { name: 'EditClient', component: 'EditClient', text: 'Edytuj Klienta', target: 'dialog' },
  deleteClient: deleteElement('clients', 'Usuń klienta'),
  deleteClientOrder: deleteElement('clientOrders', 'Usuń zlecenie', 'orders'),
  deleteClientInvoice: deleteElement('clientInvoices', 'Usuń dokument', 'invoices'),

  addNewContact: createElement('ClientsContactForm', 'Dodaj kontakt'),
  editContact: { ...createElement('ClientsContactForm', 'Edytuj kontakt'), props: { edit: true } },
  deleteContact: deleteElement('clients', 'Usuń kontakt', 'contacts', deleteShortkeys, [], 'Usunięto osobę kontaktową'),

  addNewDocument: { text: 'Załącz plik', component: 'NewClientFile', target: 'dialog', shortkey: defaultActionShortkeys, },
  downloadDocument: { text: 'Pobierz dokument', action: 'client/downloadClientFile', target: 'store' },
  deleteDocument: { ...deleteElement('clients', 'Usuń dokument', 'clientFiles'), deleteFileType: 'clientFile' },

  addNewClientAddress: createElement('NewAddress', 'Dodaj lokalizację', '600px', defaultActionShortkeys),
  editClientAddress: { name: 'editClientAddress', text: 'Edytuj lokalizacje', component: 'EditAddress', target: 'dialog', },
  deleteClientAddress: deleteElement('addresses'),

  deleteCallEntry: deleteElement('callEntries', 'Usuń wpis'),

  addNewProduct: createElement('AddProductForm', 'Dodaj produkty', '600px', null),
  editProduct: { component: 'EditProductForm', text: 'Edytuj produkt', icon: 'edytuj', target: 'dialog' },
  deleteProduct: { ...deleteElement('addresses', 'Usuń produkt', 'products', null), icon: 'usun' },

  addNewUser: createElement('NewUser', 'Nowy użytkownik'),
  deleteUser: deleteElement('users', 'Usuń użytkownika'),

  addNewIncident: createElement('NewIncident', 'Nowy incydent'),
  deleteIncident: deleteElement('incidents', 'Usuń incydent'),
  addNewIncidentMessage: createElement('NewIncidentMessage', 'Dodaj wiadomość'),

  addNewTask: createElement('NewTask', 'Nowe zadanie'),
  deleteTask: deleteElement('tasks', 'Usuń zadanie'),
  addTaskComment: { text: 'Dodaj komentarz', component: 'AddComment', target: 'dialog' }, // unused
  toggleTaskStatus: { text: 'Oznacz jako wykonane', component: 'ChangeTaskStatus', target: 'dialog', shortkey: defaultActionShortkeys, },

  editTaskDueDate: { name: 'editDueDate', text: 'Edytuj datę realizacji', component: 'EditDueDate', target: 'dialog', props: { tableName: 'tasks' } },

  addNewDepartment: createElement('NewDepartment', 'Nowy oddział'),
  deleteDepartment: deleteElement('departments', 'Usuń oddział'),

  addNewDriver: createElement('NewDriver', 'Nowy kierowca'),
  deleteDriver: deleteElement('drivers', 'Usuń kierowcę', undefined, undefined, ['vehicles', 'terminals']),

  addNewVehicle: createElement('NewVehicle', 'Nowy pojazd'),
  deleteVehicle: deleteElement('vehicles', 'Usuń pojazd'),

  addNewTrailer: createElement('NewTrailer', 'Nowa przyczepa'),
  deleteTrailer: deleteElement('trailers', 'Usuń przyczepę'),

  addNewTerminal: createElement('NewTerminal', 'Nowy terminal'),
  deleteTerminal: deleteElement('terminals', 'Usuń terminal'),

  addNewContainer: createElement('NewContainer', 'Nowy kontener'),
  deleteContainer: deleteElement('containers', 'Usuń kontener'),
  findAssignedCourses: { name: 'findAssignedCourses', text: 'Znajdź kursy', component: 'FindAssignedCourses', target: 'dialog', },

  addNewSettlement: createElement('NewSettlement', 'Nowe rozliczenie'),
  deleteSettlement: deleteElement('codDeposits', 'Usuń rozliczenie'),

  addNewContainerType: createElement('NewContainerType', 'Nowy typ kontenera'),
  deleteContainerType: deleteElement('containerTypes', 'Usuń typ kontenera'),

  addNewDebrisType: { ...createElement('NewDebrisType', 'Nowy typ odpadów'), aggregate: false },
  deleteDebrisType: deleteElement('debrisTypes', 'Usuń typ odpadu'),
  fetchDebrisTypes: { ...createElement('FetchDebrisTypes', 'Dodaj typ odpadu', '800px'), name: 'fetchDebrisTypes' },
  changeDebrisTypeTable: { name: 'changeDebrisTypeTable', text: 'Przenieś do typów kruszywa', component: 'SwitchDebrisTypeTable', target: 'dialog', },
  defaultDebrisTypeCode: { name: 'defaultDebrisTypeCode', text: 'Domyślne typy odpadów', component: 'SetDefaultDebrisTypeCode', target: 'dialog', },

  addNewAggregateType: { ...createElement('NewDebrisType', 'Nowy typ kruszywa', '640px'), aggregate: true },
  deleteAggregateType: deleteElement('aggregateTypes', 'Usuń typ kruszywa', 'debrisTypes'),
  fetchAggregateType: { ...createElement('FetchDebrisTypes', 'Dodaj typ kruszywa', '800px'), name: 'fetchAggregateType', props: { tableName: 'aggregateTypes' }, },
  changeAggregateTypeTable: { name: 'changeAggregateTypeTable', text: 'Przenieś do typów odpadów', component: 'SwitchDebrisTypeTable', target: 'dialog', props: { tableName: 'aggregateTypes' }, },

  addNewPrice: createElement('PriceForm', 'Nowa cena'),

  editDebrisPrice: editPrice('debrisPrices'),
  deleteDebrisPrice: deleteElement('debrisPrices', 'Usuń cenę', 'debrisType.id'),

  editTransportPrice: editPrice('transportPrices'),
  deleteTransportPrice: deleteElement('transportPrices', 'Usuń cenę'),

  editAggregatePrice: editPrice('aggregatePrices'),
  deleteAggregatePrice: deleteElement('aggregatePrices', 'Usuń cenę'),

  deleteAlert: deleteElement('alerts', 'Usuń komunikat'),

  sendRouteToDriver: { name: 'sendRouteToDriver', text: 'Wyślij Trasę', component: 'AssignDriver', type: 'route', target: 'dialog', },

  sendCourse: { name: 'sendCourse', text: 'Wyślij kurs', component: 'AssignDriver', type: 'course', target: 'dialog' },
  removeFromTails: { name: 'removeFromTails', text: 'Usuń z ogonów', component: 'RemoveFromTails', type: 'course', target: 'dialog' },
  moveToTails: { name: 'moveToTails', text: 'Przenieś do ogonów', action: 'course/moveToTails', target: 'store' },
  cancelCourse: { name: 'cancelCourse', text: 'Cofnij kurs', component: 'CancelCourse', target: 'dialog' },
  editCourseDueDate: { name: 'editDueDate', text: 'Edytuj datę realizacji', component: 'EditDueDate', target: 'dialog', props: { tableName: 'courses' } },
  editMultipleCoursesDueDates: { name: 'editMultipleDueDates', text: 'Zmień wybrane daty realizacji', component: 'EditDueDate', target: 'dialog', props: { multiple: true, tableName: 'courses' }, },
  editAllCoursesDueDates: { name: 'editAllDueDates', text: 'Zmień wszystkie daty realizacji', component: 'EditDueDate', target: 'dialog', props: { multiple: true, tableName: 'courses', allCourses: true }, },
  assignContainer: { name: 'assignContainer', text: 'Przypisz kontener', component: 'AssignContainer', target: 'dialog', },
  assignOrder: { name: 'assignOrder', text: 'Przypisz zlecenie', component: 'AssignOrder', target: 'dialog' },
  unassignOrder: { name: 'unassignOrder', text: 'Odepnij zlecenie', action: 'course/unassignOrder', target: 'store' },
  assignCourse: { name: 'assignCourse', text: 'Przypisz kurs', component: 'AssignCourse', target: 'dialog' },
  messageDriver: { name: 'messageDriver', text: 'Wyślij wiadomość do kierowcy', component: 'MessageDriver', target: 'dialog', },
  startCourse: { name: 'startCourse', text: 'Rozpocznij kurs', action: 'course/startCourse', target: 'store' },
  finishCourse: { name: 'finishCourse', text: 'Zakończ kurs', component: 'FinishCourseConfirmation', target: 'dialog' },
  renewCourse: { name: 'renewCourse', text: 'Cofnij zakończony kurs', action: 'course/renewCourse', target: 'store' },
  resendTextConfirmation: { name: 'resendTextConfirmation', text: 'Wyslij ponownie potwierdzenie SMS', action: 'courses/resendTextConfirmation', condition: 'withoutTextConfirmation', target: 'store' },
  generateDigitalSignature: { name: 'generateDigitalSignature', text: 'Generuj podpis cyfrowy', action: 'courses/generateDigitalSignature', condition: 'withoutTextConfirmation', target: 'store' },
  deleteCourse: { name: 'deleteCourse', ...deleteElement('courses', 'Usuń kurs') },
  showCourseHistory: { name: 'showCourseHistory', text: 'Pełna historia operacji', component: 'HistoryTable', target: 'dialog', size: 'big', },
  checkPrice: { name: 'checkPrice', text: 'Sprawdź cenę', component: 'PriceChecker', target: 'dialog', size: 'big', shortkey: checkPriceShortkeys, },
  assignKpoCardToOrder: { name: 'assignKpoCardToOrder', text: 'Przypisz KPO/KPOK', component: 'AssignBdoCardToOrder', size: 'big', target: 'dialog', },
  unassignKpoCard: { name: 'unassignKpoCard', text: 'Odepnij KPO/KPOK', action: 'course/unassignKpoCard', target: 'store', },
  rejectBdoCard: { name: 'rejectBdoCard', text: 'Odrzuć kartę', component: 'RejectBdoCard', target: 'dialog' },
  confirmBdoCard: { name: 'confirmBdoCard', text: 'Przejęcie odpadów', component: 'ConfirmBdoCard', target: 'dialog' },
  editCourseAddress: { name: 'editCourseAddress', text: 'Edytuj informacje o adresie', component: 'EditCourseAddress', target: 'dialog', },
  confirmTransport: { name: 'confirmTransport', text: 'Potwierdzenie transportu', action: 'bdoCard/confirmTransport', target: 'store', },
  massConfirmTransport: { name: 'massConfirmTransport', text: 'Potwierdzenie transportu', action: 'bdoCards/massConfirmTransport', target: 'store', },
  refreshBdoCards: { name: 'refreshBdoCards', text: 'Odśwież wyświetlone karty', action: 'bdoCards/refreshBdoCards', target: 'store', },
  refreshRevisionCards: { name: 'refreshRevisionCards', text: 'Odśwież wyświetlone karty', action: 'revisionIndex/refreshBdoCards', target: 'store', },
  refreshAssigningBdoCards: { name: 'refreshAssigningCards', text: 'Odśwież wyświetlone karty', action: 'assigningBdoCards/refreshBdoCards', target: 'store', },
  refreshInternalBdoCards: { name: 'refreshInternalCards', text: 'Odśwież wyświetlone karty', action: 'internalBdoCards/refreshBdoCards', target: 'store', },
  fetchNewBdoCards: { name: 'fetchNewBdoCards', text: 'Pobierz nowe karty', action: 'bdoCards/fetchNewBdoCards', target: 'store', },
  exportOrdersToCsv: { text: 'Eksportuj do csv', action: 'orders/exportOrdersSpreadsheet', target: 'store', shortkey: exportShortkeys, },
  exportClientOrdersToCsv: { text: 'Eksportuj do csv', action: 'clientOrders/exportOrdersSpreadsheet', target: 'store', shortkey: exportShortkeys, },

  deleteInvoice: deleteElement('invoices', 'Usuń dokument'),

  downloadInvoice: { text: 'Pobierz dokument', action: 'invoice/downloadInvoice', target: 'store', shortkey: downloadShortkeys, },
  correctInvoice: { name: 'correctInvoice', text: 'Wystaw korektę', component: 'InvoiceCorrection', target: 'dialog', shortkey: extraCreateShortkeys, size: '800px' },
  downloadMultipleInvoices: { text: 'Pobierz dokumenty F2', action: 'invoices/downloadMultipleInvoices', target: 'store', shortkey: downloadShortkeys, },
  generateInvoice: { name: 'generateInvoice', ...createElement('GenerateInvoice', 'Wygeneruj dokument', '850px') },
  generateCustomServiceInvoice: { name: 'generateCustomServiceInvoice', props: { isCustomServiceInvoice: true }, ...createElement('GenerateInvoice', 'Wygeneruj dokument za usługę', 'big', extraCreateShortkeys), },
  exportInvoiceOrdersSpreadsheet: { name: 'exportInvoiceOrdersSpreadsheet', text: 'Eksportuj do csv', component: 'ExportInvoiceOrders', target: 'dialog', },
  generatePaymentLink: { name: 'generatePaymentLink', text: 'Wygeneruj link do płatności', component: 'GeneratePaymentLink', target: 'dialog', shortkey: paymentShortkeys, mode: 'generate' },
  sendInvoices: { name: 'sendInvoices', text: 'Prześlij dokument', component: 'SendInvoices', target: 'dialog', shortkey: paymentShortkeys },

  deleteInvoiceItemTemplate: deleteElement('invoiceItemTemplates', 'Usuń usługę'),
  addNewInvoiceItemTemplate: createElement('AddNewInvoiceItemTemplate', 'Dodaj usługę'),
  editInvoiceItemTemplate: { name: 'editInvoiceItemTemplate', text: 'Edytuj usługę', component: 'EditInvoiceItemTemplate', target: 'dialog' },
  exportInvoicesToCsv: { text: 'Eksportuj do csv', action: 'invoices/exportInvoicesSpreadsheet', target: 'store', shortkey: exportShortkeys, },
  transformOrder: { name: 'transformOrder', text: 'Przekształć w zlecenie', component: 'TransformOrderIntermediary', target: 'dialog', size: 'big', },
  exportClientInvoicesToCsv: { text: 'Eksportuj do csv', action: 'clientInvoices/exportInvoicesSpreadsheet', target: 'store', shortkey: exportShortkeys, },

  generateOrderKwu: { text: 'Generuj kwu', component: 'GenerateOrderKwu', target: 'dialog' },
  generateInvoiceKwu: { text: 'Generuj kwu', action: 'invoice/generateKwu', target: 'store', tableName: 'invoice', condition: 'isNotLoading' },
  addOrderFile: { text: 'Załącz plik', component: 'NewOrderFile', target: 'dialog' },
  addBDOFile: { text: 'Załącz plik', component: 'NewBDOFile', target: 'dialog' },
  updateTransportTime: { name: 'updateTransportTime', text: 'Edytuj czas transportu', component: 'UpdateTransportTime', target: 'dialog', },
  generateKpoConfirmation: { name: 'generateKpoConfirmation', text: 'Wygeneruj potwierdzenie', component: 'GenerateKPOConfirmation', target: 'dialog', },
  reviseKpoCard: { name: 'reviseKpoCard', text: 'Wprowadź korektę', component: 'ReviseKpoCard', target: 'dialog' },

  deleteOrderSchedule: deleteElement('orderSchedules', 'Usuń harmonogram'),
  blockOrderSchedule: { name: 'blockOrderSchedule', text: 'Zablokuj harmonogram', action: 'blockOrderSchedule', component: 'ChangeScheduleStatus', target: 'dialog', shortkey: blockShortkeys, },
  unblockOrderSchedule: { name: 'unblockOrderSchedule', text: 'Odblokuj harmonogram', action: 'unblockOrderSchedule', component: 'ChangeScheduleStatus', target: 'dialog', shortkey: blockShortkeys, },

  blockClient: { name: 'blockClient', text: 'Zablokuj klienta', action: 'blockClient', component: 'ChangeClientStatus', target: 'dialog', shortkey: blockShortkeys, },
  unblockClient: { name: 'unblockClient', text: 'Odblokuj klienta', action: 'unblockClient', component: 'ChangeClientStatus', target: 'dialog', shortkey: blockShortkeys, },

  exportMultipleCoursesToCsv: { text: 'Eksportuj wybrane do csv', action: 'courses/exportCoursesSpreadsheet', target: 'store', shortkey: exportShortkeys, },
  exportAllCoursesToCsv: { text: 'Eksportuj wszystkie do csv', action: 'courses/exportAllCoursesSpreadsheet', target: 'store', shortkey: exportShortkeys, },

  changePassword: { name: 'changePassword', text: 'Zmień hasło', component: 'ChangePassword', target: 'dialog', shortkey: defaultActionShortkeys, },

  importBusinessPlaces: { name: 'importBusinessPlaces', text: 'Importuj miejsca prowadzenia działalności', target: 'function', },

  navigateToClient: { name: 'navigateToClient', text: 'Pokaż klienta', target: 'function' },
  updateContainerNotes: { name: 'updateContainerNotes', text: 'Dodaj notatkę o kontenerze', component: 'ContainerNotes', target: 'dialog' },

  fetchCommunes: { name: 'fetchCommunes', text: 'Dodaj gminę', component: 'FetchCommunes', target: 'dialog', size: '800px', shortkey: createShortkeys, },
  deleteCommune: deleteElement('communes', 'Usuń gminę'),

  deleteProspect: deleteElement('prospects', 'Usuń prospekt'),
  addProspectNote: { name: 'addProspectNote', text: 'Dodaj notatkę', component: 'AddProspectNote', target: 'dialog', shortkey: createShortkeys, },
  createClientFromProspect: { name: 'createClientFromProspect', text: 'Przekształć w klienta', component: 'CreateClientFromProspect', target: 'dialog', },
  sendInvoicesFromSingleOrder: { name: 'sendInvoicesFromSingleOrder', text: 'Prześlij dokumenty', component: 'SendInvoicesFromSingleOrder', target: 'dialog' },

  addBdoCardTemplate: { name: 'addBdoCardTemplate', text: 'Zapisz jako szablon', component: 'AddBdoCardTemplate', target: 'dialog', shortkey: createShortkeys, },
  createBdoCardFromTemplate: { name: 'createBdoCardFromTemplate', text: 'Wystaw kartę z szablonu', component: 'CreateBdoCardFromTemplate', target: 'dialog', shortkey: createShortkeys, },
  deleteBdoCardTemplate: deleteElement('bdoCardTemplates', 'Usuń szablon'),

  refreshPDF: { name: 'addBdoCardTemplate', text: 'Odśwież PDF', component: 'RefreshPDF', target: 'dialog', shortkey: createShortkeys, },

  editSettings: { name: 'editSettings', text: 'Edytuj ustawienie', component: 'EditSettings', target: 'dialog', shortkey: createShortkeys, },
  sendFilesToContacts: { name: 'sendFilesToContacts', text: 'Wyślij pliki', component: 'SendFilesToContacts', target: 'dialog', condition: 'hasSelectedItems', tableName: 'files' },

  editOrderDetails: { name: 'EditOrderDetails', text: 'Edytuj szczegóły zlecenia', component: 'EditOrderDetails', target: 'dialog', permission: 'feature-update-order-client' },

  editOrderTemplatePixelStatus: { name: 'editOrderTemplatePixelStatus', text: 'Edytuj status pixel', component: 'EditOrderTemplatePixelStatus', target: 'dialog', },

  deleteInvoiceStatus: { name: 'deleteInvoiceStatus', text: 'Usuń alert faktury', action: 'order/deleteOrderInvoiceStatus', target: 'store', permission: 'feature-delete-invoice-status', tableName: 'order' },
  confirmOrderMismatches: { name: 'confirmOrderMismatches', text: 'Zatwierdź', component: 'ConfirmOrderMismatches', target: 'dialog', },
}
