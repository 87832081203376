<template>
  <div>
    <v-tooltip
      v-if="containerTypeDiffrentThanOrdered"
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-icon
          size="15"
          :color="isMismatchConfirmed ? 'success' : 'warning'"
          class="alert-icon mr-1"
          v-bind="attrs"
          v-on="on"
        >
          mdi-alert
        </v-icon>
      </template>
      <span v-if="isMismatchConfirmed">Zatwierdzono różnicę przypisanego i zamówionego typu kontenera</span>
      <span v-else>Wybrany typ kontenera jest inny niż zamówiony</span>
    </v-tooltip>
    <span>{{ value }}</span>
  </div>
</template>

<script>
export default {
  name: 'TableColumnContainerType',
  props: {
    rowData: {
      type: Object,
      required: true
    },
    value: {
      type: String,
      required: true
    },
  },
  computed: {
    containerTypeDiffrentThanOrdered() {
      const setContainerType = this.rowData.orderContainerContainerTypeVolume
      const orderedByUserContainerType = this.rowData.orderContainerTypeVolume
      if (setContainerType && orderedByUserContainerType) {
        return setContainerType !== orderedByUserContainerType
      } else return false
    },
    isMismatchConfirmed() {
      return this.rowData.orderAllowContainerMismatch
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-icon {
  cursor: pointer;
}
</style>
