<template>
  <div>
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
    />
    <Tabs
      v-if="configured"
      ref="tabs"
      :tabs="tabs"
      persistent
      @goTo="goTo"
    />
    <Table
      ref="table"
      :key="tableName"
      :table-name="tableName"
      :has-grouped-entries="true"
      @contextMenu="openContextMenu"
      @configured="configured = true"
    >
      <template #tableTop>
        <FiltersBar
          v-if="tableName !== 'logCRMActiveUsers'"
          ref="tableTop"
          :table-name="tableName"
          search-bar
          date-range-picker
          show-filters-button
          table-filters
        />
      </template>
      <template
        #stats
        v-if="tableName !== 'logCRMActiveUsers'"
      >
        <div class="ml-10 mr-12 d-flex">
          <span class="table-statistics__label">Łączna długość połączeń:</span>
          <span class="table-statistics__value">{{ formattedDurationSum }}</span>
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import Header from '../../components/Layout/Header'
import Table from '../../components/Table/Table'
import Tabs from '../../components/Layout/Tabs'
import FiltersBar from '../../components/Filters/FiltersBar'
import defaultTableEventsMixin from '../../mixins/defaultTableEventsMixin'
import webSocketMixin from '../../mixins/webSocketMixin'
import { mapActions, mapState } from 'vuex'

const tabs = Object.freeze([
  { value: 'callEntries', tableName: 'callEntries', text: 'Centrala', filters: [] },
  { value: 'logCRMActiveUsers', tableName: 'logCRMActiveUsers', text: 'Zalogowani w LogCRM', filters: [] }
])

export default {
  components: {
    Header,
    Table,
    FiltersBar,
    Tabs
  },
  mixins: [defaultTableEventsMixin, webSocketMixin],
  data: () => ({
    tableName: 'callEntries',
    configured: false,
    tabs
  }),
  channels: {
    CallEntryIndexChannel: {
      received(data) {
        this.captureChanges(data, 'callEntries')
        this.getCounters()
      }
    },
    CrmUsersIndexChannel: {
      received(data) {
        this.captureChanges(data, 'logCRMActiveUsers')
      }
    }
  },
  computed: {
    ...mapState({
      counters: state => state.callEntries.counters
    }),
    formattedDurationSum() {
      const seconds = this.counters.durationSum || 0

      const formattedHours = Math.floor(seconds / 3600)
        .toString()
        .padStart(2, '0')
      const formattedMinutes = Math.floor((seconds % 3600) / 60)
        .toString()
        .padStart(2, '0')
      const formattedSeconds = (seconds % 60).toString().padStart(2, '0')

      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
    }
  },
  mounted() {
    this.subscribeSocket('LogCrmActiveUserIndexChannel')
    this.subscribeSocket('CallEntryIndexChannel')
    this.unsubscribeAction = this.$store.subscribeAction({
      after: action => {
        if (['callEntries/getItems'].includes(action.type)) {
          this.getCounters()
        }
      }
    })
  },
  beforeDestroy() {
    this.unsubscribeSocket('CallEntryIndexChannel')
    this.unsubscribeSocket('LogCrmActiveUserIndexChannel')
    this.unsubscribeAction()
  },
  methods: {
    ...mapActions({
      getCounters: function(dispatch) {
        return dispatch('callEntries/getCounters')
      }
    }),
    goTo(tab) {
      this.tableName = tab.value
    }
  }
}
</script>
