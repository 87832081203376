import actions from '../actions'

export default {
  header: [
    { ...actions.addNewSettlement, create: true }
  ],
  table: {
    maximumDateRange: 1,
    sorting: {
      sortBy: 'id',
      sortDesc: true
    },
    filters: [
      { name: 'driverId', text: 'Kierowca', options: 'drivers', filterBy: '' },
      { name: 'codDepositType', text: 'Typ rozliczenia', options: 'codDepositTypes' }
    ],
    parameters: [
      { name: 'id', text: 'Nr rozliczenia', value: 'id', show: true },
      { name: 'refNumber', text: 'Nr zlecenia', value: 'order.refNumber', show: true },
      { name: 'courseType', text: 'Typ kursu', value: 'course.courseType', show: true },
      { name: 'depositedAt', text: 'Data rozliczenia', value: 'depositedAt', show: true },
      { name: 'codDepositType', text: 'Typ rozliczenia', value: 'codDepositType', sortable: false, show: true },
      { name: 'chargedByType', text: 'Typ pobierającego', value: 'chargedBy.humanizedType', show: true },
      { name: 'user', text: 'Pobrał', value: 'chargedBy.fullName', show: true },
      { name: 'clientName', text: 'Wpłacający', value: 'clientName', clientLabel: true, show: true },
      { name: 'requiredAmount', text: 'Do rozliczenia', value: 'requiredAmount', show: true },
      { name: 'depositedAmount', text: 'Pobrano', value: 'depositedAmount', show: true },
      { name: 'description', text: 'Notatka', value: 'description', show: true },
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false }
    ],
    actions: [
      { ...actions.deleteSettlement, icon: 'usun' }
    ],
    menu: [
      { ...actions.deleteSettlement }
    ]
  },
  details: {
    title: 'Rozliczenie nr {{id}}',
    actions: [
      { ...actions.deleteSettlement, redirect: true }
    ],
    sections: [
      {
        name: 'general',
        text: 'Informacje ogólne',
        type: 'left',
        endpoint: 'codDeposits',
        fields: [
          { name: 'id', text: 'Nr rozliczenia', value: 'id', type: 'textField', col: 3 },
          { name: 'orderNumber', text: 'Nr zlecenia', value: 'order.refNumber', type: 'textField', to: 'singleOrder', idPath: 'order.id', col: 3 },
          { name: 'courseType', text: 'Typ kursu', value: 'course.courseType', type: 'textField', col: 3, editable: false },
          { name: 'depositedAt', text: 'Data rozliczenia', value: 'depositedAt', type: 'date', col: 3, editable: true, rules: ['required'] },
          { name: 'chargedByHumanizedType', text: 'Typ pobierającego', value: 'chargedBy.humanizedType', col: 3 },
          { name: 'chargedByFullName', text: 'Pobrał', value: 'chargedBy.fullName', col: 3 },
          { name: 'clientName', text: 'Wpłacający', value: 'clientName', type: 'textField', col: 3, editable: true, rules: ['required'] },
          { name: 'requiredAmount', text: 'Do rozliczenia', value: 'requiredAmount', type: 'textField', col: 3 },
          { name: 'depositedAmount', text: 'Pobrano', value: 'depositedAmount', type: 'textField', col: 3 },
          { name: 'description', text: 'Notatka', value: 'description', type: 'textArea', col: 3, editable: true },
        ]
      }
    ]
  }
}
