<template>
  <div>
    <v-tooltip
      v-if="debrisTypeDiffrentThanReported"
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-icon
          size="15"
          :color="isMismatchConfirmed ? 'success' : 'warning'"
          class="alert-icon mr-1"
          v-bind="attrs"
          v-on="on"
        >
          mdi-alert
        </v-icon>
      </template>
      <span v-if="isMismatchConfirmed">Zatwierdzono różnicę raportowanego typu odpadu i typu odpadu ze zlecenia
      </span>
      <span v-else>Wybrany typ odpadu jest inny niż raportowany</span>
    </v-tooltip>
    <span>{{ value }}</span>
  </div>
</template>

<script>
export default {
  name: 'TableColumnDebrisType',
  props: {
    rowData: {
      type: Object,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    tableName: {
      type: String,
      required: true,
      default: ''
    }
  },
  computed: {
    debrisTypeDiffrentThanReported() {
      const debrisTypeId = this.rowData?.debrisTypeId
      const reportedDebrisTypeId = this.rowData?.reportedDebrisTypeId
      if (debrisTypeId && reportedDebrisTypeId) {
        return debrisTypeId !== reportedDebrisTypeId
      } else return false
    },
    isMismatchConfirmed() {
      return this.rowData.orderAllowDebrisMismatch
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-icon {
  cursor: pointer;
}
</style>
