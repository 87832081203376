<template>
  <div>
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
    />
    <Tabs
      v-if="configured"
      ref="tabs"
      :tabs="tabs"
      persistent
      @goTo="goTo"
    />
    <Table
      :key="tableName"
      ref="table"
      :table-name="tableName"
      @openDetails="openTableDetails"
      @contextMenu="openContextMenu"
      @configured="configured = true"
    >
      <template #tableTop>
        <FiltersBar
          :key="currentTab"
          ref="tableTop"
          :table-name="tableName"
          table-mode-filter
          :table-mode-filter-props="{ class: 'mr-4' }"
          search-bar
          date-range-picker
          show-filters-button
          table-filters
        />
      </template>
    </Table>
  </div>
</template>

<script>
import Header from '../../components/Layout/Header'
import Tabs from '../../components/Layout/Tabs'
import Table from '../../components/Table/Table'
import FiltersBar from '../../components/Filters/FiltersBar'
import defaultTableEventsMixin from '../../mixins/defaultTableEventsMixin'
import { mapState, mapActions, mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

const tabs = Object.freeze([
  {
    value: 'mineTasks',
    text: 'Moje zadania',
    filters: [
      { name: 'assignedUserId', hide: true, disableSave: true },
      { name: 'taskTypes', filterBy: [], hide: false, disableSave: false }
    ]
  },
  {
    value: 'tasks',
    text: 'Ogólne',
    filters: [
      { name: 'assignedUserId', hide: false, disableSave: false, filterby: '' },
      { name: 'taskTypes', filterBy: [], hide: false, disableSave: false }
    ]
  },
  {
    value: 'coursesConfirmations',
    text: 'Potwierdzenia kursów',
    filters: [
      { name: 'assignedUserId', hide: false, disableSave: false, filterby: '' },
      { name: 'taskTypes', filterBy: ['Potwierdzenie kursu'], hide: true, disableSave: true }
    ]
  },
  {
    value: 'inactiveClients',
    text: 'Nieaktywni klienci',
    filters: [
      { name: 'assignedUserId', hide: false, disableSave: false, filterby: '' },
      { name: 'taskTypes', filterBy: ['Brak aktywności klienta'], hide: true, disableSave: true }
    ]
  },
  {
    value: 'incorrectMessages',
    text: 'Niepoprawna wiadomość SMS',
    filters: [
      { name: 'assignedUserId', hide: false, disableSave: false, filterby: '' },
      { name: 'taskTypes', filterBy: ['Niepoprawna wiadomość SMS'], hide: true, disableSave: true }
    ]
  }
])

export default {
  components: {
    Header,
    Tabs,
    Table,
    FiltersBar
  },
  mixins: [defaultTableEventsMixin],
  data: () => ({
    tableName: 'tasks',
    currentTab: null,
    configured: false,
    firstInit: true,
    tabs
  }),
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    ...mapGetters({
      getFilterValue: 'tables/getFilterValue',
      getSavedFilterBy: 'tables/getSavedFilterBy'
    })
  },
  methods: {
    ...mapActions({
      setTableFilters: 'tables/setTableFilters'
    }),
    goTo(rawTab) {
      const tab = cloneDeep(rawTab)
      const previousTab = this.currentTab
      const nextTab = tab.value
      // --------------------------- ASSIGNED USER ID FILTER ---------------------------
      //     1. - In "mineTasks" tab set userId filterBy value to CURRENT USER IDF in assignedUserId filter
      if (nextTab === 'mineTasks') {
        const originalTabIndex = this.tabs.findIndex(t => tab.value === 'mineTasks')
        const assignedUserFilterIndex = this.tabs[originalTabIndex].filters.findIndex(
          filter => filter.name === 'assignedUserId'
        )
        tab.filters[assignedUserFilterIndex].filterBy = {
          name: this.user.fullName,
          value: this.user.id
        }
      }
      //     2. - When switching from "mineTasks" tab to other tab, bring back assignedUserId filterBy value
      if (previousTab === 'mineTasks' && nextTab !== 'mineTasks') {
        const savedFilterValue = this.getSavedFilterBy(this.tableName, 'assignedUserId')
        const originalTabIndex = this.tabs.findIndex(t => t.value === this.currentTab)
        const assignedUserIdFilterIndex = this.tabs[originalTabIndex].filters.findIndex(
          f => f.name === 'assignedUserId'
        )

        this.tabs[originalTabIndex].filters[assignedUserIdFilterIndex].filterBy = savedFilterValue
        tab.filters[assignedUserIdFilterIndex].filterBy = savedFilterValue
      }

      // --------------------------- TASK TYPES FILTER ---------------------------
      if (['mineTasks', 'tasks'].includes(nextTab) || this.firstInit) {
        // when returning to 'mineTasks' or 'tasks' tab get taskTypes filterBy value from saved value
        // other tabs are automatically manipulating taskTypes filterBy value, so we don't want to override it
        const savedFilterValue = this.getSavedFilterBy(this.tableName, 'taskTypes')
        const originalTabIndex = this.tabs.findIndex(t => t.value === 'mineTasks')
        const taskTypeFilterIndex = this.tabs[originalTabIndex].filters.findIndex(f => f.name === 'taskTypes')
        this.tabs[originalTabIndex].filters[taskTypeFilterIndex].filterBy = savedFilterValue
        tab.filters[taskTypeFilterIndex].filterBy = savedFilterValue
        this.firstInit = false
      }

      this.currentTab = tab.value
      this.setTableFilters({ filters: tab.filters, tableName: this.tableName, disableFetch: true })
    }
  }
}
</script>
