<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col
          v-for="(field, index) in filteredFields"
          :key="field.name + index"
          cols="12"
          sm="12"
          :md="field.col"
          class="py-1"
        >
          <DialogInput
            :field="field"
            :data="data"
            :detail="detail"
          />
        </v-col>
      </v-row>
      <v-col v-if="warnings.length">
        <Warning
          v-for="(warning, index) in warnings"
          :key="index"
          :message="warning.text"
          :color="warning.color"
          class="my-2"
        />
      </v-col>
    </v-container>
  </div>
</template>

<script>
import DialogInput from '../../Forms/Inputs/DialogInput'
import detailsSectionWarningMixin from '../../../mixins/detailsSectionWarningMixin'

export default {
  components: {
    DialogInput
  },
  mixins: [detailsSectionWarningMixin], // warnings
  props: {
    detail: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: false
    }
  },
  computed: {
    filteredFields() {
      return this.detail.fields
        .filter((field) => !field.condition || field.condition(this.data))
    },
  },
}
</script>
