import {
  formatValue
} from '../utils/typesEnum'
class History {
  constructor(history = {}) {
    this.content = history.content
    this.date = history.date
    this.id = history.id
    this.user = history.user
  }
}

class File {
  constructor(file = {}) {
    this.filename = file.filename
    this.fileType = file.fileType
    this.id = file.id
    this.size = file.size
    this.timestamp = file.timestamp
    this.url = file.url
  }
}

class Order {
  constructor(order = {}) {
    this.address = order.address || {
      formattedAddress: null,
      notes: null,
      department: { name: null }
    }
    this.aggregateAmount = order?.aggregateAmount
    this.aggregateType = order?.aggregateType
    this.bdoCardId = order.bdoCardId || null
    this.bdoNumber = order.bdoNumber
    this.bdoStatus = order.bdoStatus
    this.bdoFile = order.bdoFile || null
    this.client = order.client || {
      name: null,
      email: null,
      phoneNumber: null,
      bdoNumber: null
    }
    this.codDeposits = order.codDeposits
    this.container = order.container || {
      id: null,
      refNumberWithType: null
    }
    this.containerNotes = order.containerNotes
    this.containerType = order.containerType || {
      name: null
    }
    this.containerWithType = order.containerWithType
    this.coordinatesType = order.coordinatesType
    this.courses = order.courses || []
    this.createdAt = order.createdAt
    this.customServices = order.customServices

    this.debrisType = order.debrisType || {
      name: null,
      code: null
    }
    this.driver = order.driver || {
      fullName: null
    }
    this.driverNotes = order?.driverNotes
    this.dueDate = order.dueDate
    this.displaySwapScreen = order.displaySwapScreen
    this.files = order.files || [new File()]
    this.history = order.history || [new History()]
    this.id = order.id
    this.invoices = order?.invoices
    this.invoiceStatus = order.invoiceStatus
    this.leaveDate = order.leaveDate
    this.middleman = order.middleman
    this.origin = order?.origin
    this.orderTemplateId = order.orderTemplateId
    this.parsedData = order.parsedData
    this.payer = order.payer
    this.payment = order.payment || {
      debrisGrossValue: null,
      debrisNetValue: null,
      discount: null,
      discountJustification: null,
      leftToPay: null,
      paidValue: null,
      paymentDueDays: null,
      paymentStatus: null,
      paymentType: null,
      linkSettlements: [],
      settlementType: null,
      total: null,
      transportGrossValue: null,
      transportNetValue: null,
      vatPercentage: null
    }
    this.phoneNumber = order.phoneNumber
    this.pickupDate = order.pickupDate
    this.refNumber = order.refNumber
    this.reportedDebrisType = order?.reportedDebrisType
    this.reportedWeight = order?.reportedWeight
    this.reports = order.reports
    this.swapCourse = order.swapCourse || null
    this.swapOrderId = order.swapOrderId
    this.swapOrderRefNumber = order.swapOrderRefNumber
    this.timeSlot = order.timeSlot
    this.userNotes = order?.userNotes
    this.withoutDocuments = order?.withoutDocuments
    this.wordpressId = order.wordpressId
    this.wordpressUrl = order.wordpressUrl
    this.allowContainerMismatch = order.allowContainerMismatch
    this.allowDebrisMismatch = order.allowDebrisMismatch
  }
}

class OrderPayer {
  constructor(order = {}) {
    this.id = order.payerId
    this.name = order.payerName
    this.clientType = order.payerClientType
    this.bdoNumber = order.payerBdoNumber
    this.bdoType = order.payerBdoType
    this.settlementType = order.payerSettlementType
    this.blocked = order.payerBlocked
  }
}

class SimplifiedOrder {
  constructor(order = {}) {
    this.address = {
      discount: order.addressDiscount
    }
    this.container = {
      containerType: {
        id: order.containerContainerTypeId
      }
    }
    this.containerType = {
      id: order.containerTypeId
    }
    this.course = {
      timeSlot: order.timeSlot,
      timeNotes: order.leaveCourseTimeNotes
    }
    this.clientId = order.clientId
    this.debrisType = {
      id: order.debrisTypeId
    }
    this.done = order.done
    this.driverNotes = order.driverNotes
    this.id = order.id
    this.parsedData = order.parsedData
    this.payment = {
      debrisNetValue: order.debrisNetValue,
      debrisGrossValue: order.debrisGrossValue,
      paymentType: order.paymentType,
      paymentDueDays: order.paymentDueDays,
      settlementType: order.settlementType,
      discount: order.paymentDiscount,
      vatPercentage: order.vatPercentage,
      discountJustification: order.discountJustification,
      origin: order.paymentOrigin
    }
    this.phoneNumber = order.phoneNumber
    this.refNumber = order.refNumber
    this.userNotes = order.userNotes
    this.reportedDebrisTypeId = order.reportedDebrisTypeId
  }
}

class NewOrder {
  constructor(order = {}) {
    this.course = {
      courseType: order?.courses?.[0]?.courseType || 'Podstawienie',
      dueDate: order?.courses?.[0]?.dueDate || new Date().toISOString().substr(0, 10),
      fromTime: order?.courses?.[0]?.timeSlot?.[0] || '8:00',
      toTime: order?.courses?.[0]?.timeSlot?.[1] || '18:00',
      userNotes: order?.courses?.[0]?.userNotes || null,
      timeNotes: order?.courses?.[0]?.timeNotes || null
    }
    this.order = {
      addressId: order?.address?.id || null,
      aggregateAmount: order?.aggregateAmount || 5,
      clientId: order?.client?.id || null,
      containerTypeId: order?.containerType?.id || null,
      debrisTypeId: order?.debrisType?.id || null,
      driverNotes: order?.driverNotes,
      middlemanId: order?.middlemanId || order.middleman?.id,
      payerId: order?.payer?.id || null,
      phoneNumber: order?.phoneNumber,
      quantity: 1,
      userNotes: order?.userNotes
    }
    this.payment = {
      debrisGrossValue: order?.payment?.debrisGrossValue / 100 || null,
      debrisNetValue: order?.payment?.debrisNetValue / 100 || null,
      discount: order?.payment?.discount / 100 || null,
      discountJustification: order?.payment?.discountJustification || null,
      paymentDueDays: order.payment?.paymentDueDays || null,
      paymentType: order?.payment?.paymentType || 'Gotówka przy podstawieniu',
      settlementType: order?.payment?.settlementType || 'Faktura',
      transportGrossValue: order?.payment?.transportGrossValue / 100 || null,
      transportNetValue: order?.payment?.transportNetValue / 100 || null,
      vatPercentage: order?.payment?.vatPercentage || 8,
      origin: order?.payment?.origin || 'R', // R as default to ensure that the order is created price origin
    }
  }
}

class Course {
  constructor(course = {}) {
    this.id = course.id
    this.anyContainer = course.anyContainer
    this.timeSlot = course?.timeSlot || [course.fromTime, course.toTime]
    this.courseStatus = course.courseStatus
    this.courseType = course.courseType
    this.dueDate = course.dueDate
    this.withoutDocuments = course.withoutDocuments
    this.swapWithoutDocuments = course.swapWithoutDocuments
    this.withAssignableKpoCards = course.withAssignableKpoCards
    this.withoutTextConfirmation = course.withoutTextConfirmation
    this.timeNotes = course.timeNotes
    this.phoneNumber = course.phoneNumber
    this.address = {
      formattedAddress: course.formattedAddress,
      id: course.addressId,
    }
    this.bdoCard = {
      id: course.bdoCardId,
      cardNumber: course.bdoCardNumber,
      cardStatus: course.bdoCardStatus,
      realTransportTime: course.bdoCardRealTransportTime,
      vehicleRegNumber: course.bdoCardVehicleRegNumber
    }
    this.bdoFile = course.orderBdoFileId && {
      id: course.orderBdoFileId,
      filename: course.orderBdoFileName,
      size: course.orderBdoFileSize,
      url: course.orderBdoFileUrl,
    }
    this.client = {
      id: course.clientId,
      name: course.clientName
    }
    this.driverDailySequence = course.driverDailySequence
    this.sendSquence = course.sendSquence
    this.driver = {
      id: course.driverId,
      fullName: course.driverFullName || null
    }
    this.order = {
      address: {
        id: course.orderAddressId,
        notes: course.orderAddressNotes,
        phoneNumber: course.orderAddressPhoneNumber,
        formattedAddress: course.orderAddressFormattedAddress,
        commune: {
          name: course.orderCommuneName
        }
      },
      id: course.orderId,
      refNumber: course.orderRefNumber,
      reportedWeight: course.orderReportedWeight,
      bdoNumber: course.orderBdoNumber,
      aggregateAmount: course.orderAggregateAmount,
      reportedDebrisType: {
        id: course.reportedDebrisTypeId,
        displayName: course.orderReportedDebrisTypeDisplayName
      },
      debrisType: {
        id: course.debrisTypeId,
        name: course.debrisTypeName,
        code: course.debrisTypeCode,
        displayName: course.debrisTypeDisplayName,
        clientDisplayName: course.debrisTypeClientDisplayName,
        aggregate: course.debrisTypeAggregate
      },
      aggregateType: {
        id: course.aggregateTypeId,
        name: course.aggregateTypeName,
        code: course.aggregateTypeCode,
        displayName: course.aggregateTypeDisplayName,
        clientDisplayName: course.aggregateTypeClientDisplayName,
        aggregate: course.aggregateTypeAggregate
      },
      client: {
        id: course.orderClientId,
        bdoNumber: course.orderClientBdoNumber,
        bdoType: course.orderClientBdoType,
        blocked: course.orderClientBlocked,
        name: course.orderClientName,
        settlementType: course.orderClientSettlementType
      },
      payment: {
        totalGrossValue: course.paymentTotalGrossValue,
        paidValue: course.paymentPaidValue,
        paymentType: course.paymentType,
        paymentStatus: course.paymentStatus,
        transportNetValue: course.paymentTransportNetValue,
        debrisNetValue: course.paymentDebrisNetValue
      },
      containerType: {
        id: course.orderContainerTypeId,
        name: course.orderContainerTypeName
      },
      container: {
        id: course.orderContainerId,
        refNumberWithType: course.containerWithType
      },
      containerWithType: course.containerWithType,
      containerPickable: course.containerPickable
    }
    this.nextCourseInfo = {
      id: course.nextCourseId,
      dueDate: course.nextCourseDueDate,
      orderId: course.nextCourseOrderId
    }
    this.previousCourseInfo = {
      id: course.previousCourseId,
      dueDate: course.previousCourseDueDate,
      orderId: course.previousCourseOrderId
    }
    this.swapCourse = course.swapCourseId && {
      address: {
        id: course.swapAddressId,
        formattedAddress: course.swapAddressFormattedAddress,
      },
      id: course.swapCourseId,
      anyContainer: course.swapCourseAnyContainer,
      courseType: course.swapCourseCourseType,
      timeSlot: [course.swapCourseFromTime, course.swapCourseToTime],
      bdoCard: {
        id: course.swapBdoCardId,
        cardNumber: course.swapBdoCardNumber,
        cardStatus: course.swapBdoCardStatus,
        vehicleRegNumber: course.swapBdoCardVehicleRegNumber,
        realTransportTime: course.swapBdoCardRealTransportTime,
        plannedTransportTime: course.swapBdoCardPlannedTransportTime
      },
      client: {
        id: course.swapClientId,
        name: course.swapClientName,
        bdoType: course.swapClientBdoType
      },
      containerType: {
        name: course.swapContainerTypeName
      },
      order: {
        id: course.swapOrderId,
        refNumber: course.swapOrderRefNumber,
        bdoNumber: course.swapOrderBdoNumber,
        bdoStatus: course.swapOrderBdoStatus,
        reportedWeight: course.swapOrderReportedWeight,
        aggregateAmount: course.swapOrderAggregateAmount,
        client: {
          id: course.swapOrderClientId,
          name: course.swapOrderClientName,
          bdoType: course.swapOrderClientBdoType
        },
        containerPickable: course.swapContainerPickable,
        containerType: {
          name: course.swapOrderContainerTypeName
        },
        debrisType: {
          id: course.swapDebrisTypeId,
          name: course.swapDebrisTypeName,
          code: course.swapDebrisTypeCode,
          displayName: course.swapDebrisTypeDisplayName,
          clientDisplayName: course.swapDebrisTypeClientDisplayName,
          aggregate: course.swapDebrisTypeAggregate
        },
        aggregateType: {
          id: course.swapAggregateTypeId,
          name: course.swapAggregateTypeName,
          code: course.swapAggregateTypeCode,
          displayName: course.swapAggregateTypeDisplayName,
          clientDisplayName: course.swapAggregateTypeClientDisplayName,
          aggregate: course.swapAggregateTypeAggregate
        },
        payment: {
          totalGrossValue: course.swapPaymentTotalGrossValue,
          paidValue: course.swapPaymentPaidValue,
          paymentType: course.swapPaymentType,
          paymentStatus: course.swapPaymentStatus,
          transportNetValue: course.swapPaymentTransportNetValue,
          debrisNetValue: course.swapPaymentDebrisNetValue
        },
        reportedDebrisType: {
          id: course.swapReportedDebrisTypeId,
          displayName: course.swapReportedDebrisTypeDisplayName
        },
        containerWithType: course.swapContainerWithType
      }
    }
    this.containerType = {
      id: course.containerTypeId,
      name: course.containerTypeName
    }
    this.lastRouteDueDate = course.lastRouteDueDate
  }
}

class SwapCourse {
  constructor(course = {}) {
    this.addressId = course.swapAddressId
    this.id = course.swapCourseId
    this.anyContainer = course.swapCourseAnyContainer
    this.courseType = course.swapCourseCourseType
    this.timeSlot = [course.swapCourseFromTime, course.swapCourseToTime]
    this.bdoCardId = course.swapBdoCardId
    this.bdoCardNumber = course.swapBdoCardNumber
    this.bdoCardStatus = course.swapBdoCardStatus
    this.bdoCardVehicleRegNumber = course.swapBdoCardVehicleRegNumber
    this.bdoCardRealTransportTime = course.swapBdoCardRealTransportTime
    this.clientId = course.swapClientId
    this.clientBdoType = course.swapClientBdoType
    this.orderId = course.swapOrderId
    this.orderRefNumber = course.swapOrderRefNumber
    this.orderBdoNumber = course.swapOrderBdoNumber
    this.orderBdoStatus = course.swapOrderBdoStatus
    this.containerPickable = course.swapContainerPickable
    this.orderContainerTypeName = course.swapOrderContainerTypeName
    this.orderReportedWeight = course.swapOrderReportedWeight
    this.orderAggregateAmount = course.swapOrderAggregateAmount
    this.containerWithType = course.swapContainerWithType
    this.paymentTotalGrossValueWithDiscount = course.swapPaymentTotalGrossValueWithDiscount
    this.paymentPaidValue = course.swapPaymentPaidValue
    this.paymentType = course.swapPaymentType
    this.paymentStatus = course.swapPaymentStatus
    this.paymentTransportNetValue = course.swapPaymentTransportNetValue
    this.paymentDebrisNetValue = course.swapPaymentDebrisNetValue
    this.aggregateTypeId = course.swapAggregateTypeId
    this.aggregateTypeName = course.swapAggregateTypeName
    this.aggregateTypeCode = course.swapAggregateTypeCode
    this.aggregateTypeDisplayName = course.swapAggregateTypeDisplayName
    this.aggregateTypeClientDisplayName = course.swapAggregateTypeClientDisplayName
    this.aggregateTypeAggregate = course.swapAggregateTypeAggregate
    this.debrisTypeId = course.swapDebrisTypeId
    this.debrisTypeName = course.swapDebrisTypeName
    this.debrisTypeCode = course.swapDebrisTypeCode
    this.debrisTypeDisplayName = course.swapDebrisTypeDisplayName
    this.debrisTypeClientDisplayName = course.swapDebrisTypeClientDisplayName
    this.debrisTypeAggregate = course.swapDebrisTypeAggregate
    this.reportedDebrisTypeId = course.swapReportedDebrisTypeId
    this.reportedDebrisTypeDisplayName = course.swapReportedDebrisTypeDisplayName
    this.orderContainerTypeVolume = course.swapOrderContainerTypeVolume
    this.orderContainerContainerTypeVolume = course.swapOrderContainerContainerTypeVolume
    this.orderAllowContainerMismatch = course.swapOrderAllowContainerMismatch
    this.orderAllowDebrisMismatch = course.swapOrderAllowDebrisMismatch
  }
}

class PageOffset {
  constructor() {
    this.excludedIds = []
    this.excludedOffset = 0
  }
}

class Pagination {
  constructor(pagination = {}, itemsPerPage) {
    this.itemsLength = pagination.itemsLength || 0
    this.itemsPerPage = itemsPerPage || 150
    this.page = pagination.page || 1
    this.pageCount = pagination.pageCount || 1
  }
}

// Rename Items to Collection
class Items {
  constructor(payload = {}) {
    this.items = payload.items || payload.collection || []
    this.simplifiedList = payload.simplifiedList || []
  }
}

class Table {
  constructor() {
    this.filters = []
    this.filtersEnabled = true
    this.pagination = new Pagination()
    this.parameters = []
    this.search = ''
    this.sorting = {}
    this.specialFlag = {}
  }
}

class Client {
  constructor(client = {}) {
    this.active = client.active || true
    this.addresses = client.addresses || []
    this.aggregateType = client.aggregateType
    this.bdoNumber = client.bdoNumber
    this.bdoType = client.bdoType
    this.blockages = client.blockages || []
    this.blocked = client.blocked || false
    this.blockExplanation = client.blockExplanation
    this.clientType = client.clientType || 'Osoba fizyczna'
    this.contacts = client.contacts || []
    this.controlName = client.controlName
    this.contractNumber = client.contractNumber
    this.contractDate = client.contractDate
    this.createdAt = client.createdAt
    this.clientChanges = client.clientChanges || []
    this.email = client.email
    this.id = client.id
    this.invoiceAddress = client.invoiceAddress
    this.invoiceBuyerName = client.invoiceBuyerName
    this.invoiceCity = client.invoiceCity
    this.invoiceNip = client.invoiceNip
    this.invoicePhoneNumber = client.invoicePhoneNumber
    this.invoicePostalCode = client.invoicePostalCode
    this.isMiddleman = client.isMiddleman || false
    this.middlemen = client.middlemen
    this.name = client.name
    this.notes = client.notes
    this.patron = client.patron
    this.phoneNumber = client.phoneNumber
    this.purchaseLimit = client.purchaseLimit
    this.purchaseLimitRemaining = client.purchaseLimitRemaining
    this.purchaseLimitExceeded = client.purchaseLimitExceeded
    this.paymentDueDays = client.paymentDueDays
    this.paymentType = client.paymentType
    this.settlementType = client.settlementType
    this.settlementType = client.settlementType || 'Faktura imienna'
    this.signatureRequired = client.signatureRequired || false
  }
}

class AddressClient {
  constructor(address = {}) {
    this.bdoNumber = address.clientBdoNumber
    this.bdoType = address.clientBdoType
    this.blocked = address.clientBlocked
    this.clientType = address.clientClientType
    this.declarationNotPresent = address.clientDeclarationNotPresent
    this.email = address.clientEmail
    this.id = address.clientId
    this.invoiceAddress = address.clientInvoiceAddress
    this.invoiceBuyerName = address.clientInvoiceBuyerName
    this.invoiceCity = address.clientInvoiceCity
    this.invoiceNip = address.clientInvoiceNip
    this.invoicePhoneNumber = address.clientInvocePhoneNumber
    this.invoicePhoneNumber = address.clientInvoiceBuyerName
    this.isMiddleman = address.clientIsMiddleman
    this.middlemanName = address.clientMiddlemanName
    this.name = address.clientName
    this.notes = address.clientNotes
    this.phoneNumber = address.clientPhoneNumber
    this.purchaseLimit = address.clientPurchaseLimit
    this.purchaseLimitExceeded = address.clientPurchaseLimitExceeded
    this.purchaseLimitRemaining = address.clientPurchaseLimitRemaining
    this.settlementType = address.clientSettlementType
  }
}

class Address {
  constructor(address = {}) {
    this.address = {
      formattedAddress: address.formattedAddress || null,
      lat: address.location?.lat || null,
      lng: address.location?.lng || null
    }
    this.communeId = address.commune?.id || null
    this.contactName = address.contactName || null
    this.departmentId = address.department?.id || null
    this.discount = address.discount || 0
    this.discountJustification = address.discountJustification || null
    this.email = address.email || null
    this.name = address.name || null
    this.notes = address.notes || null
    this.paymentDueDays = address.paymentDueDays || null
    this.paymentType = address.paymentType || null
    this.phoneNumber = address.phoneNumber || null
    this.products = address.products || []
    this.remarks = address.remarks || null
    this.invoiceNotes = address.invoiceNotes || null
  }
}
class Invoice {
  constructor(invoice = {}) {
    this.address = invoice.address
    this.bill = invoice.billInvoiceNumber && {
      invoiceNumber: invoice.billInvoiceNumber
    }
    this.buyerName = invoice.buyerName
    this.city = invoice.city
    this.correctionInvoices = invoice.correctionInvoices || []
    this.createdAt = invoice.createdAt
    this.customServices = invoice.customServices
    this.clientId = invoice.clientId
    this.exportDate = invoice.exportDate
    this.grossValue = invoice.grossValue
    this.id = invoice.id
    this.invoice = invoice.invoiceInvoiceNumber && {
      invoiceNumber: invoice.invoiceInvoiceNumber
    }
    this.invoiceConnectionType = invoice.invoicesConnectionType
    this.invoiceItems = invoice.invoiceItems || []
    this.invoiceNumber = invoice.invoiceNumber
    this.invoiceChanges = invoice.invoiceChanges || []
    this.invoicePdf = invoice.invoicePdfFileId && {
      id: invoice.invoicePdfFileId,
      filename: invoice.invoicePdfFileName,
      url: invoice.invoicePdfFileUrl
    }
    this.issueDate = invoice.issueDate
    this.kwuFile = invoice.kwuFileId && {
      id: invoice.kwuFileId,
      filename: invoice.kwuFileName,
      size: invoice.kwuFileSize,
      url: invoice.kwuFileUrl,
      fileType: invoice.kwuFileType
    }
    this.latestInvoice = invoice.latestInvoiceId && {
      id: invoice.latestInvoiceId,
      correctionType: invoice.latestInvoiceCorrectionType,
      buyerName: invoice.latestInvoiceBuyerName,
      city: invoice.latestInvoiceCity,
      nip: invoice.latestInvoiceNip,
      address: invoice.latestInvoiceAddress,
      postalCode: invoice.latestInvoicePostalCode,
      notes: invoice.latestInvoiceNotes,
      invoiceItems: invoice.latestInvoiceItems,
      saleDate: invoice.latestInvoiceSaleDate || '2024-10-16', // braki od tad
      issueDate: invoice.latestInvoiceIssueDate || '2024-10-16',
      paymentDueDate: invoice.latestInvoicePaymentDueDate || '2024-10-16',
    }
    this.mainInvoice = invoice.mainInvoiceId && {
      id: invoice.mainInvoiceId,
      invoiceNumber: invoice.mainInvoiceInvoiceNumber,
      createdAt: invoice.mainInvoiceCreatedAt,
      netValue: invoice.mainInvoiceNetValue,
      grossValue: invoice.mainInvoiceGrossValue,
    }
    this.grossValue = invoice.grossValue
    this.netValue = invoice.netValue
    this.nip = invoice.nip
    this.notes = invoice.notes
    this.optimaStatus = invoice.optimaStatus || false
    this.orders = invoice.orders?.map(order => ({ ...order, client: { id: order.clientId } })) || []
    this.paidValue = invoice.paidValue || 0
    this.paymentDate = invoice.paymentDate
    this.paymentDueDate = invoice.paymentDueDate
    this.paymentStatus = invoice.paymentStatus
    this.paymentType = invoice.paymentType
    this.postalCode = invoice.postalCode
    this.saleDate = invoice.saleDate
    this.settlementType = invoice.settlementType
    this.withDebrisInfo = invoice.withDebrisInfo
    this.withInvoiceReport = invoice.withInvoiceReport
    this.optimaStatus = invoice.optimaStatus
    this.fromModel = true
  }
}

class Incident {
  constructor(incident = {}) {
    this.client = new Client(incident.client)
    this.createdAt = incident.createdAt
    this.id = incident.id
    this.incidentSource = incident.incidentSource
    this.latestMessageReportedAt = incident.latestMessageReportedAt
    this.messages = incident.messages || []
    this.notes = incident.notes
    this.phoneNumber = incident.phoneNumber
  }
}

class Schedule {
  constructor(schedule = {}) {
    this.active = schedule.active === undefined ? false : schedule.active
    this.address = schedule.address || []
    this.client = new Client(schedule.client)
    this.containerType = schedule.containerType
    this.createdAt = schedule.createdAt
    this.debrisType = schedule.debrisType
    this.discount = schedule.discount
    this.discountJustification = schedule.discountJustification
    this.formattedDays = schedule.formattedDays || []
    this.friday = schedule.friday || false
    this.id = schedule.id
    this.monday = schedule.monday || false
    this.nextRealisationDate = schedule.nextRealisationDate
    this.paymentType = schedule.paymentType
    this.phoneNumber = schedule.phoneNumber
    this.saturday = schedule.saturday || false
    this.settlementType = schedule.settlementType || 'Faktura'
    this.thursday = schedule.thursday || false
    this.timeSlot = schedule.timeSlot
    this.tuesday = schedule.tuesday || false
    this.wednesday = schedule.wednesday || false
  }
}

class Swap {
  constructor(order = {}) {
    this.order = {
      addressId: order.address?.id || null,
      anyContainer: false,
      containerTypeId: order.containerType?.id || null,
      debrisTypeId: order.debrisType?.id || null,
      driverNotes: order.driverNotes || null,
      orderId: order.id || null,
      phoneNumber: order.phoneNumber || null,
      pickupContainerTypeId: null,
      quantity: 1,
      scheduleDays: null,
      userNotes: order.userNotes || null,
      assignedContainerTypeId: order?.container?.containerType?.id || null,
      reportedDebrisTypeId: order.reportedDebrisTypeId || null
    }
    this.course = {
      dueDate: new Date().toISOString().substr(0, 10),
      fromTime: order.courses?.[0]?.timeSlot[0] || '8:00',
      leaveNotes: null,
      toTime: order.courses?.[0]?.timeSlot[1] || '18:00',
      userNotes: order.courses?.[0]?.userNotes || null,
      timeNotes: order.courses?.[0]?.timeNotes || ''
    }
    this.payment = {
      debrisGrossValue: order.payment?.debrisGrossValue / 100 || null,
      debrisNetValue: order.payment?.debrisNetValue / 100 || null,
      discount: order.payment?.discount / 100 || null,
      discountJustification: order.payment?.discountJustification || null,
      paymentDueDays: order.payment?.paymentDueDays || null,
      paymentType: order.payment?.paymentType || 'Gotówka przy zabraniu',
      settlementType: order.payment?.settlementType || 'Faktura',
      vatPercentage: order.payment?.vatPercentage || 8,
      origin: order.payment?.origin || 'R',
    }
  }
}

class Pickup {
  constructor(order = { courses: [] }) {
    this.course = {
      dueDate: new Date().toISOString().substr(0, 10),
      fromTime: order.courses?.[0]?.timeSlot[0] || '8:00',
      toTime: order.courses?.[0]?.timeSlot[1] || '18:00',
      userNotes: order.courses?.[0]?.userNotes || null,
      phoneNumber: order.phoneNumber || null
    }
    this.order = {
      addressId: order.address?.id || null,
      anyContainer: false,
      orderId: order.id || null,
      phoneNumber: order.phoneNumber || null,
      pickupContainerTypeId: null,
      quantity: 1,
      containerTypeId: order.containerType?.id || null,
      assignedContainerTypeId: order?.container?.containerType?.id || null,
      reportedDebrisTypeId: order.reportedDebrisTypeId || null
    }
  }
}

class BdoCardTemplate {
  constructor(data = {}) {
    this.id = data.id || null
    this.reportedWeight = data.reportedWeight || null
    this.wasteCodeExtended = data.wasteCodeExtended || false
    this.receiverBusinessPlaceId = data.receiverBusinessPlace?.id || data.receiverBusinessPlaceId || null
    this.carrierBusinessPlaceId = data.carrierBusinessPlaceId || data.carrierBusinessPlace?.id || null // BE does not provide this field, user has to fill it manually
    this.senderCity = data.senderCity || data.senderBusinessPlaceId || null // BE does not provide this field, user has to fill it manually
    this.debrisTypeId = data.reportedDebrisType?.id || data.debrisType?.id || data.reportedDebrisTypeId || null
    this.vehicleRegNumber = data.vehicleRegNumber || null
    this.wasteCodeExtendedDescription = data.wasteCodeExtendedDescription || null
  }
}

class ProductFromLastDoneOrder {
  constructor(order = {}) {
    this.addressDiscount = order.address?.discount
    this.containerTypeId = order.containerType?.id
    this.containerTypeName = order.containerType?.name
    this.debrisTypeAggregate = order.debrisType?.aggregate
    this.debrisTypeClentDisplayName = order.debrisType?.clientDisplayName
    this.debrisTypeCode = order.debrisType?.code
    this.debrisTypeDisplayName = order.debrisType?.displayName
    this.debrisTypeId = order.debrisType?.id
    this.debrisTypeName = order.debrisType?.name
    this.id = order.id
    this.netValue = order.payment?.debrisNetValue
    this.parsedNetValue = formatValue(order.payment?.debrisNetValue, 'price')
    this.userNotes = order.userNotes
    this.timeSlot = order.course?.timeSlot || ['8:00', '18:00']
    this.phoneNumber = order.phoneNumber
    this.paymentType = order.payment?.paymentType
    this.settlementType = order.payment?.settlementType
    this.paymentDiscount = order.payment?.discount
    this.paymentDiscountJustification = order.payment?.discountJustification
    this.paymentVatPercentage = order.payment?.vatPercentage
    this.fromLastDoneOrder = true // This is always set to true in the map function
  }
}

class FlatBdoCard {
  constructor(data) {
    this.id = data.id
    this.revisedWeight = data.revisedWeight || null
    this.cardStatus = data.cardStatus
    this.cardNumber = data.cardNumber
    this.vehicleRegNumber = data.vehicleRegNumber
    this.plannedTransportTime = data.plannedTransportTime
    this.realTransportTime = data.realTransportTime
    this.revisionNumber = data.revisionNumber
    this.reportedWeight = data.reportedWeight
    this.orderType = data.orderType
    this.wasteCodeExtended = data.wasteCodeExtended
    this.wasteCodeExtendedDescription = data.wasteCodeExtendedDescription
    this.senderName = data.senderName
    this.receiverName = data.receiverName
    this.clientId = data.client ? data.client.id : null
    this.clientName = data.client ? data.client.name : null
    this.reportedDebrisTypeId = data.reportedDebrisType.id
    this.reportedDebrisTypeDisplayName = data.reportedDebrisType.displayName
    this.reportedDebrisTypeCode = data.reportedDebrisType.code
    this.revisedDebrisTypeId = data.reportedDebrisType ? data.reportedDebrisType.id : null
    this.revisedDebrisTypeDisplayName = data.reportedDebrisType ? data.reportedDebrisType.displayName : null
    this.revisedDebrisTypeCode = data.reportedDebrisType ? data.reportedDebrisType.code : null
    this.communeId = data.commune.id
    this.communeType = data.commune.communeType
    this.communeName = data.commune.name
    this.orderBdoFileId = data.bdoFile.id
    this.orderBdoFileUrl = data.bdoFile.url
    this.senderBusinessPlaceId = data.senderBusinessPlace.id
    this.senderBusinessPlaceName = data.senderBusinessPlace.name
    this.senderBusinessPlaceAddress = data.senderBusinessPlace.address
    this.senderBusinessPlaceDisplayName = data.senderBusinessPlace.displayName
    this.receiverBusinessPlaceId = data.receiverBusinessPlace.id
    this.receiverBusinessPlaceName = data.receiverBusinessPlace.name
    this.receiverBusinessPlaceAddress = data.receiverBusinessPlace.address
    this.receiverBusinessPlaceDisplayName = data.receiverBusinessPlace.displayName
    this.carrierBusinessPlaceId = data.receiverBusinessPlace.id
    this.carrierBusinessPlaceName = data.receiverBusinessPlace.name
    this.carrierBusinessPlaceAddress = data.receiverBusinessPlace.address
    this.carrierBusinessPlaceDisplayName = data.receiverBusinessPlace.displayName
  }
}

export {
  Order,
  NewOrder,
  OrderPayer,
  Items,
  PageOffset,
  Pagination,
  Table,
  Client,
  Address,
  AddressClient,
  Schedule,
  Incident,
  Invoice,
  SimplifiedOrder,
  Swap,
  Pickup,
  BdoCardTemplate,
  Course,
  SwapCourse,
  ProductFromLastDoneOrder,
  FlatBdoCard
}
