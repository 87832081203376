<template>
  <div class="d-flex align-center">
    <v-icon
      v-if="!hideIcon"
      size="20"
      :color="color"
    >
      mdi-alert
    </v-icon>
    <span
      class="warning-message ml-2"
      :style="{'color': color}"
    >{{ message }}</span>
  </div>
</template>

<script>
export default {
  name: 'Warning',
  props: {
    message: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'red'
    },
    hideIcon: {
      type: Boolean,
      default: false
    }
  }
}
</script>
