<script>
import get from 'lodash/get'

export default {
  computed: {
    warnings() {
      const warnings = []
      const data = this.data || this.$attrs.data
      this.detail?.conditionalWarnings?.forEach(warning => {
        const targetA = get(data, warning.targetA, null)
        const targetB = get(data, warning.targetB, null)
        switch (warning.condition) {
          case 'isNotEqual':
            if (targetA && targetB && targetA !== targetB) {
              warnings.push({
                text: warning.errorMessage(data),
                color: warning.errorColor ? warning.errorColor(data) : 'red'
              })
            }
            break
          case 'exists':
            if (targetA) {
              warnings.push({ text: targetA })
            }
            break
        }
      })
      return warnings
    }
  }
}
</script>
